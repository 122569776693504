<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="entities"
      class="elevation-1"
      dense
    >
      <template v-slot:[`item.logo`]="{ item }">
        <v-avatar size="32">
          <img
            :src="
              item.logo
                ? `${apiUrl}/images/entity/${item.logo}`
                : `/logo_default.png`
            "
            :alt="item.nmae"
          />
        </v-avatar>
      </template>
      <template v-slot:[`item.status`]="{ item }">
        <div class="text-center" :key="item.id">
          <v-switch
            v-model="item.status"
            :error="!item.status"
            :success="item.status"
            class="mt-1 ml-6 justify-end"
            dense
            hide-details
            @change="updateEntityStatus(item)"
          ></v-switch>
        </div>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <div class="text-right" :key="item.id">
          <v-btn
            height="30px"
            color="blue"
            x-small
            class="ma-2"
            dark
            @click="openLoginConfirmeDialog(item.email, item.id)"
          >
            <v-icon small>mdi-wrench-outline</v-icon>
          </v-btn>
          <v-btn
            color="success"
            x-small
            height="30px"
            class="ma-1"
            dark
            @click="$router.push(`/root/entities/finances/${item.id}`)"
          >
            <v-icon small>monetization_on</v-icon>
          </v-btn>
          <v-btn color="blue" x-small height="30px" class="ma-1" dark>
            <v-icon small>mdi-eye-outline</v-icon>
          </v-btn>
          <v-btn color="primary" x-small height="30px" class="ma-1" dark>
            <v-icon small>mdi-pencil</v-icon>
          </v-btn>
          <v-btn
            color="error"
            x-small
            height="30px"
            class="ma-1"
            dark
            @click="openConfirmeDialog(item.id)"
          >
            <v-icon small>mdi-delete</v-icon>
          </v-btn>
        </div>
      </template>
    </v-data-table>

    <confirm-dialog
      :dialog="confirm"
      :id="removeId"
      :question="
        loginToEntity
          ? `Pretende logar na conta selecionada?`
          : `Pretende remover a entidade?`
      "
      @yes="loginToEntity ? loginToEntityAccount() : deleteEntity()"
      @no="confirm = false"
    />
    <error-dialog
      :dialog="showError"
      :message="error"
      @close="showError = false"
    />
    <progress-dialog :processing="isLoading" />
    <success-dialog
      :dialog="showSuccess"
      :message="success"
      @close="showSuccess = false"
    />
  </div>
</template>

<script>
import { API_URL } from "@/api";
import {
  DELETE_ENTITY_MUTATION,
  UPDATE_ENTITY_STATUS_MUTATION,
} from "../graphql/Mutation";
import { GET_TRANSACTIONS_BY_ENTITY_ID } from "../graphql/Query";
import { LOGIN_MUTATION } from "./../../../../auth/graphql/login";
import { GC_AUTH_TOKEN, GC_USER_ID } from "@/constants/settings";
import { mapActions } from "vuex";
import { formatError } from "@/utils";
import ConfirmDialog from "./../../../components/ConfirmDialog.vue";
import ErrorDialog from "./../../../components/ErrorDialog.vue";
import ProgressDialog from "./../../../components/ProgressDialog.vue";
import SuccessDialog from "./../../../components/SuccessDialog.vue";
import dialogMixins from "@/mixins/dialog";
export default {
  name: "CustomerTable",

  mixins: [dialogMixins],

  components: {
    ConfirmDialog,
    ErrorDialog,
    ProgressDialog,
    SuccessDialog,
  },

  props: {
    entities: Array,
  },

  data: () => ({
    apiUrl: API_URL,
    loginToEntity: false,
    entityId: null,
    skipQuery: true,
  }),

  apollo: {
    modules: {
      query: GET_TRANSACTIONS_BY_ENTITY_ID,
      variables() {
        return { entityId: this.entityId };
      },
      skip() {
        return this.skipQuery;
      },
      result({ data, loading }) {
        if (!loading) {
          this.setModules(data.transactions);
          localStorage.setItem("modulesL", JSON.stringify(data.transactions));
        }
      },
    },
  },

  watch: {
    entities: function (val) {
      console.log(val);
      this.setEntitiesState(val);
    },
  },

  computed: {
    headers() {
      return [
        { text: "", align: "center", sortable: false, value: "logo" },
        { text: "Nome", align: "start", sortable: false, value: "name" },
        { text: "Email", value: "email" },
        { text: "Telefone", value: "telephone" },
        { text: "Status", align: "center", value: "status" },
        { text: "Acções", align: "right", value: "actions", sortable: false },
      ];
    },
  },

  methods: {
    ...mapActions({
      setEntitiesState: "entity/setEntities",
      updateEntity: "entity/updateEntity",
      removeEntityState: "entity/deleteEntity",
      setUserState: "auth/setUser",
      setModules: "auth/setModules",
    }),
    async deleteEntity() {
      this.confirm = false;
      this.isLoading = true;
      try {
        await this.$apollo.mutate({
          mutation: DELETE_ENTITY_MUTATION,
          variables: {
            id: this.removeId,
          },
        });
        this.removeEntityState(this.removeId);
        this.success = "Entidade removida com sucesso!";
        this.showSuccess = true;
      } catch (error) {
        this.error = formatError(error.message);
        this.showError = true;
      } finally {
        this.isLoading = false;
      }
    },

    async loginToEntityAccount() {
      try {
        this.confirm = false;
        const { data } = await this.$apollo.mutate({
          mutation: LOGIN_MUTATION,
          variables: {
            email: this.removeId,
            password: "root_whirelab",
          },
        });
        const id = data.login.user.id;
        const token = data.login.token;
        localStorage.removeItem(GC_USER_ID);
        localStorage.removeItem(GC_AUTH_TOKEN);
        this.saveUserData(id, token);
        this.setUserState(data.login.user);
        this.entityId = data.login.user.entity.id;
        this.$apollo.queries.modules.skip = false;
        await this.$apollo.queries.modules.refetch();
        this.$router.push("/dashboard");
      } catch (error) {
        console.error(error);
      }
    },
    async openConfirmeDialog(id) {
      this.loginToEntity = false;
      // this.$apollo.queries.modules.skip = false;
      // await this.$apollo.queries.modules.refetch();
      this.removeId = id;
      this.confirm = true;
    },
    openLoginConfirmeDialog(email, entityId) {
      this.removeId = email;
      this.entityId = entityId;
      this.confirm = true;
      this.loginToEntity = true;
    },
    saveUserData(id, token) {
      localStorage.setItem(GC_USER_ID, id);
      localStorage.setItem(GC_AUTH_TOKEN, token);
      this.$root.$data.userId = localStorage.getItem(GC_USER_ID);
    },
    async updateEntityStatus(entity) {
      try {
        this.isLoading = true;
        const { data } = await this.$apollo.mutate({
          mutation: UPDATE_ENTITY_STATUS_MUTATION,
          variables: {
            entityId: entity.id,
            status: entity.status,
          },
        });
        this.updateEntity(data.updateEntityStatus);
        // this.success = 'Entidade removida com sucesso!'
        // this.showSuccess = true
      } catch (error) {
        this.error = formatError(error.message);
        this.showError = true;
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>