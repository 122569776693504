import gql from "graphql-tag";
import entityFragment from "./EntityFragment";

export const ENTITIES_QUERY = gql`
  query Entities {
    entities: entities {
      ...${entityFragment}
    }
  }
`;

export const GET_ENTITY_TRANSACTIONS_QUERY = gql`
  query GetEntityTransactions($entityId: ID!) {
    transactions: getEntityTransactions(entityId: $entityId) {
      id
      amountPaid
      code
      date
      module {
        name
      }
      plan {
        id
        duration
      }
      status
      totalUsers
      totalProfiles
      value
    }
  }
`;

export const GET_TRANSACTIONS_BY_ENTITY_ID = gql`
  query GetTransactionsByEntityId($entityId: ID!) {
    transactions: getTransactionsByEntityId(entityId: $entityId) {
      id
      amountPaid
      date
      expired
      module {
        id
        minimumProfiles
        name
        permissions {
          id
          name
        }
        pricePerProfile
        pricePerUser
        profileInterval
        userInterval
      }
      plan {
        id
        duration
        minimumDuration
      }
      totalProfiles
      totalUsers
      type
      value
      balance
    }
  }
`;
