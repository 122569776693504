<template>
  <div class="fill-heigt" fluid>
    <v-row>
      <v-col cols="11" md="8">
        <div class="text-left subtitle" style="margin-top:3%">
          Painel de Clientes 
        </div>
      </v-col>
      
      <v-col cols="12" md="4">
        <div class="text-right">
          <v-text-field
            append-icon="mdi-magnify"
            label="Pesquisar"
            single-line
            hide-details
            dense
            outlined
          ></v-text-field>
        </div>
      </v-col>
    </v-row>
    <v-divider class="my-6"></v-divider>
    <v-row>
      <v-col cols="12" md="12">
        <v-card v-if="$apollo.queries.entities.loading">
          <v-skeleton-loader
            :type="
              `table-thead, table-row-divider@5, table-tfoot`
            "
          ></v-skeleton-loader>
        </v-card>
        <customer-table v-else :entities="getEntitiesState" />
      </v-col>  
    </v-row>  
  </div> 
</template>

<script>
import { ENTITIES_QUERY } from './../graphql/Query'
import { mapActions, mapGetters } from 'vuex'
import CustomerTable from '../components/CustomerTable.vue'
export default {
  name: 'Entity',
  components: { CustomerTable },
  data: () => ({
    entities: []
  }),
  apollo: {
    entities: {
      query: ENTITIES_QUERY
    }
  },
  watch: {
    entities: function (val) {
      this.setEntitiesState(val)
    }
  },
  computed: {
    ...mapGetters({
      getEntitiesState: 'entity/getEntities'
    }),
  },
  methods: {
    ...mapActions({
      setEntitiesState: 'entity/setEntities',
      removeEntityState: 'entity/deleteEntity'
    }),
  }
}
</script>