import gql from "graphql-tag";
import entityFragment from "./EntityFragment";

export const DELETE_ENTITY_MUTATION = gql`
  mutation DeleteEntity($id: ID!) {
    deleteEntity(id: $id) {
      id
    }
  }
`;

export const DELETE_ENTITY_MODULE_MUTATION = gql`
  mutation DdeleteEntityModule($id: ID!) {
    deleteEntityModule(id: $id) {
      id
    }
  }
`;

export const UPDATE_ENTITY_MODULE_MUTATION = gql`
  mutation UpdateEntityModule(
    $id: ID!
    $entityModuleInput: EntityModuleInput!
  ) {
    updateEntityModule(id: $id, entityModuleInput: $entityModuleInput) {
      id
      amountPaid
      code
      date
      module {
        name
      }
      plan {
        id
        duration
      }
      status
      totalUsers
      totalProfiles
      value
    }
  }
`;

export const UPDATE_ENTITY_MODULE_STATUS_MUTATION = gql`
  mutation UpdateEntityModuleStatus($id: ID!, $status: Boolean!) {
    updateEntityModuleStatus(id: $id, status: $status) {
      id
      amountPaid
      code
      date
      module {
        name
      }
      plan {
        id
        duration
      }
      status
      totalUsers
      totalProfiles
      value
    }
  }
`;

export const UPDATE_ENTITY_MODULE_AMOUNT_PAID_MUTATION = gql`
  mutation UpdateEntityModuleAmountPaid($id: ID!, $amountPaid: Float!) {
    updateEntityModuleAmountPaid(id: $id, amountPaid: $amountPaid) {
      id
      amountPaid
      code
      date
      module {
        name
      }
      plan {
        id
        duration
      }
      status
      totalUsers
      totalProfiles
      value
    }
  }
`;

export const ADD_DAYS_TO_TRANSACTIONS_MUTATION = gql`
  mutation AddDaysToTransactions($id: ID!, $days: Int!) {
    addDaysToTransactions(id: $id, days: $days) {
      id
      amountPaid
      code
      date
      module {
        name
      }
      plan {
        id
        duration
      }
      status
      totalUsers
      totalProfiles
      value
    }
  }
`;

export const SUBTRACT_DAYS_FROM_TRANSACTIONS_MUTATION = gql`
  mutation SubtractDaysFromTransactions($id: ID!, $days: Int!) {
    subtractDaysFromTransactions(id: $id, days: $days) {
      id
      amountPaid
      code
      date
      module {
        name
      }
      plan {
        id
        duration
      }
      status
      totalUsers
      totalProfiles
      value
    }
  }
`;

export const UPDATE_ENTITY_STATUS_MUTATION = gql`
  mutation UpdateEntityStatus($entityId: ID!, $status: Boolean) {
    updateEntityStatus(entityId: $entityId, status: $status) {
      ...${entityFragment}
    }
  }
`;
