import gql from 'graphql-tag'
import userFragment from './userFragment'

export const LOGIN_MUTATION = gql`
  mutation Login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      token
      user {
        ...${userFragment}
      }
    }
  }
`